export default class NetworkTab {
  constructor(elem, opts) {
    this.target = elem
    this.opts = opts

    this.activeClass = 'is-active'

    this.eventBinds()
  }

  eventBinds() {
    this.target.addEventListener('click', ()=> {
      this.scrollContentTop()
      this.tabswitching()
    })

  }

  tabswitching() {
    const targetArr = document.querySelectorAll('[data-tab-contents]');

    for (let i = 0; i < targetArr.length; i++) {
      const element = targetArr[i];

      if( element.dataset.tabContents === this.opts.content ){
        element.classList.add(this.activeClass)
      } else {
        element.classList.remove(this.activeClass)
      }
      
    }

  }

  scrollContentTop(){
    const contentElem = document.getElementById('networkContentWrap');
    const headerElem = document.getElementById('headerContent');
    const contentElemTopPos = window.pageYOffset + contentElem.getBoundingClientRect().top - headerElem.clientHeight - 50
    scrollTo(0, contentElemTopPos);
  }
}
