const State = new class State {
  constructor(elem, opts) {
    this.target = elem
    this.opts = opts

    this.breakpoint = 768

    this.currentDevice
    this.windowHeight

    this.scrollPosi
    this.scrollOffset

    this.eventBinds()
  }

  eventBinds() {
    window.addEventListener('load', ()=> {
      this.checkDevice()
      this.checkWindowHeight()
      this.checkScrollPosi()
    })

    window.addEventListener('scroll', ()=> {
      this.checkScrollPosi()
    })

    window.addEventListener('resize', ()=> {
      this.checkDevice()
      this.checkWindowHeight()
      this.checkScrollPosi()
    })
  }

  checkDevice() {
    if (window.innerWidth >= this.breakpoint) {
      this.currentDevice = "pc"
    } else {
      this.currentDevice = "sp"
    }
    if(this.currentDevice === 'pc') {
      this.scrollOffset = 100
    } else {
      this.scrollOffset = 20
    }
  }

  checkWindowHeight() {
    this.windowHeight = window.innerHeight
  }

  checkScrollPosi() {
    this.scrollPosi = window.pageYOffset || document.documentElement.scrollTop
  }
}

export default State