export default class ScrollTop {
  constructor(elem, opts) {
    this.target = elem
    this.opts = opts

    this.eventBinds()
  }

  eventBinds() {
    this.target.addEventListener('click', ()=> {
      this.scrollTop()
    })
  }

  scrollTop() {
    window.scroll({top: 0, behavior: 'smooth'});
  }

}
