export default class SmoothScroll {
    constructor(elem, opts) {
        this.target = elem
        this.opts = opts

    //   this.activeClass = 'is-active'

    this.eventBinds()
    }

    eventBinds() {
        this.target.addEventListener('click', ()=> {
          this.buttonscroll()
        })
    
      }
    
      buttonscroll() {
        const smoothScrollTrigger = document.querySelectorAll('a[href^="#"]');
    
        for (let i = 0; i < smoothScrollTrigger.length; i++) {
          const element = smoothScrollTrigger[i];

          preventDefault();
          let href = smoothScrollTrigger[i].getAttribute('href'); // 各a要素のリンク先を取得
          let targetElement = document.getElementById(href.replace('#', '')); // リンク先の要素（コンテンツ）を取得
          
          const rect = targetElement.getBoundingClientRect().top; // ブラウザからの高さを取得
          const offset = window.pageYOffset; // 現在のスクロール量を取得
          const gap = 60; // 固定ヘッダー分の高さ
          const target = rect + offset - gap; //最終的な位置を割り出す
  
          // 4. スムースにスクロール
          window.scrollTo({
              top: target,
              behavior: 'smooth',
          });
  
    
          
        }
    
      }
}