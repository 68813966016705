import Swiper, {Autoplay} from 'swiper';
import 'swiper/css';

Swiper.use([Autoplay])

export default function(){
  const serviceSlider = new Swiper(".js-service-slider", {
    speed: 1000,
    autoplay: {
      delay: 3000,
    },
    slidesPerView: "auto",
    loop: true,
    spaceBetween: 30,
    // freeMode: true,
    // loopedSlides: 5,
    centeredSlides: true
  });

  window.addEventListener('changeTab', () => {
    for(let j=0; j<serviceSlider.length; j++){
      serviceSlider[j].update()
    }
  })

  // const tabElmAry = document.getElementsByClassName('p-service__tab-button')
  // for(let i=0; i<tabElmAry.length; i++){
  //   tabElmAry[i].addEventListener('click', () => {
  //     for(let j=0; j<serviceSlider.length; j++){
  //       setTimeout(() => {
  //         serviceSlider[j].update()

  //       }, 1000)
  //     }
  //   })
  // }
}