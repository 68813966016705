import State from './CheckState'

export default class MenuControl {
  constructor(elem, opts) {
    this.elm = elem
    this.opts = opts

    this.page = this.opts.page

    this.breakpoint = 1025

    this.headerWrapElm = this.elm
    this.hoverTriggerElmAry = document.getElementsByClassName('js-menu-hover-trigger')
    this.hoverTargetElmAry = document.getElementsByClassName('js-menu-hover-target')

    this.menuLinkTriggerElmAry = document.getElementsByClassName('js-menu-click-trigger')
    this.menuLinkTargetElmAry = document.getElementsByClassName('js-menu-click-target')
    this.menuLinkTargetInnerElmAry = document.getElementsByClassName('js-menu-click-target-inner')
    this.menuLinkOpenFlgAry = []

    this.menuOpenBtnElm = document.getElementById('menuOpenBtn')
    this.menuWrapElm = document.getElementById('menuWrap')
    this.headerWrapElm = this.elm

    this.menuOpenFlg = false

    this.topMenuControlClass = 'is-menu--show'

    this.eventBinds()
  }

  eventBinds() {
    const _this = this

    // ロード
    window.addEventListener('load', ()=> {
      if(_this.page === 'top') {
        _this.topPageMenuControl()
      } else {
        if(State.currentDevice === 'pc') {
          _this.pagetopClassToggle()
        }
      }
    })

    // リサイズ
    window.addEventListener('resize', ()=> {
      if(State.currentDevice === 'pc' && _this.page !== 'top') {
        _this.pagetopClassToggle()
      }
    })

    // スクロール
    window.addEventListener('scroll', ()=> {
      // ページトップ判定&メニュー制御
      if(State.currentDevice === 'pc' && _this.page !== 'top') {
        _this.pagetopClassToggle()
      }
      // トップページの場合
      if(_this.page === 'top') {
        _this.topPageMenuControl()
      }
    })

    // メニュー開閉ボタンのクリック制御
    _this.menuOpenBtnElm.addEventListener('click', function(){
      _this.spMenuDisplayToggle()
    })

    // メニューホバーの制御（PC）
    _this.menuHoverControl()

    // メニュークリックの制御（SP）
    _this.menuClickControl()

    _this.init()
  }

  init() {
    // console.log("init");
  }

  /**
   * @method menuHoverControl
   * @desc メニューのマウスオーバー&リーブ制御（PC用）
   * @desc 下層メニューがあるメニューをマウスオーバーした場合、下層メニューを開く
   */
  menuHoverControl() {
    const _this = this

    // 下層メニューありのメニュー項目を
    // -- マウスホバー時（PCのみ）
    for(let i=0; i<_this.hoverTriggerElmAry.length; i++){
      _this.hoverTriggerElmAry[i].addEventListener('mouseenter', function(){
        if(State.currentDevice === 'pc') {
          _this.hoverTriggerElmAry[i].classList.add('is-active')
          for(let j=0; j<_this.hoverTargetElmAry.length; j++){
            _this.hoverTargetElmAry[j].classList.remove('is-active')
          }
          _this.headerWrapElm.classList.add('is-expand')
          _this.hoverTargetElmAry[i].classList.add('is-active')
        }
      })
    }

    // -- マウスリーブ時（PCのみ）
    _this.headerWrapElm.addEventListener('mouseleave', function(){
      _this.headerWrapElm.classList.remove('is-expand')
      for(let i=0; i<_this.hoverTargetElmAry.length; i++){
        _this.hoverTriggerElmAry[i].classList.remove('is-active')
        _this.hoverTargetElmAry[i].classList.remove('is-active')
      }
    })
  }

  /**
   * @method menuClickControl
   * @desc メニューのクリック制御（SP用）
   * @desc 下層メニューがあるメニューをクリックした場合、下層メニューを開く
   */
  menuClickControl() {
    const _this = this

    for(let i=0; i<_this.menuLinkTriggerElmAry.length; i++){
      _this.menuLinkOpenFlgAry.push(false)
      _this.menuLinkTriggerElmAry[i].addEventListener('click', function(){
        if(State.currentDevice === 'sp') {
          if(!_this.menuLinkOpenFlgAry[i]){
            _this.menuLinkOpenFlgAry[i] = true
            _this.menuLinkTriggerElmAry[i].classList.add('is-open')
            _this.menuLinkTargetElmAry[i].classList.add('is-open')
            _this.menuLinkTargetElmAry[i].style.height = String(_this.menuLinkTargetInnerElmAry[i].clientHeight) + 'px'
          }else{
            _this.menuLinkOpenFlgAry[i] = false
            _this.menuLinkTriggerElmAry[i].classList.remove('is-open')
            _this.menuLinkTargetElmAry[i].classList.remove('is-open')
            _this.menuLinkTargetElmAry[i].style.height = '0px'
          }
        }
      })
    }
  }

  /**
   * @method spMenuDisplayToggle
   * @desc メニューの開閉制御（SP用）
   */
  spMenuDisplayToggle() {
    const _this = this
    if(!_this.menuOpenFlg){
      _this.spMenuOpen()
      _this.menuOpenFlg = true
    }else{
      _this.spMenuClose()
      _this.menuOpenFlg = false
    }
  }

  /**
   * @method spMenuOpen
   * @desc メニューを開く（SP用）
   */
  spMenuOpen() {
    const _this = this
    _this.menuWrapElm.classList.add('is-active')
    _this.menuOpenBtnElm.classList.add('is-close')
  }

  /**
   * @method spMenuClose
   * @desc メニューを閉じる（SP用）
   */
  spMenuClose() {
    const _this = this
    _this.menuWrapElm.classList.remove('is-active')
    _this.menuOpenBtnElm.classList.remove('is-close')
  }

  /**
   * @method pagetopClassToggle
   * @desc ページトップにいるかの判定&制御
   */
  pagetopClassToggle() {
    const _this = this
    if(window.scrollY <= 0){
      _this.headerWrapElm.classList.add('is-pagetop')
    }else{
      _this.headerWrapElm.classList.remove('is-pagetop')
    }
  }

  /**
   * @method topPageMenuControl
   * @desc トップページのメニュー表示制御
   */
  topPageMenuControl(){
    const _this = this

    if (State.scrollPosi > State.windowHeight) {
      _this.elm.classList.add(_this.topMenuControlClass)
    } else {
      _this.elm.classList.remove(_this.topMenuControlClass)
    }
  }

  /**
   * @method isPC
   * @desc PC/SP判定
   */
  isPC() {
    const _this = this
    if (window.innerWidth >= _this.breakpoint) {
      return true
    } else {
      return false
    }
  }
}