export default class ServiceTab {
  constructor(elem, opts) {
    this.target = elem
    this.opts = opts
    this.activeClass = 'is-active'
    this.category = 'consulting'
    this.changeTabEvent = new CustomEvent('changeTab', {bubbles: false, cancelable: false});

    this.eventBinds()
  }

  eventBinds() {
    this.setFirstTab()
    this.target.addEventListener('click', ()=> {
      this.scrollContentTop()
      this.tabswitching()
    })
  }

  setFirstTab(){
    // get query parameter
    const paramQueryAry = location.search.replace('?', '').split('&')
    for(let i=0; i<paramQueryAry.length; i++){
      let paramQuery = paramQueryAry[i].split('=')
      if(paramQuery[0] === 'tab'){
        this.category = paramQuery[1]
      }
    }
    // update tab
    const targetArr = document.querySelectorAll('[data-tab-contents]')
    for (let i = 0; i < targetArr.length; i++) {
      const element = targetArr[i]
      if( element.dataset.tabContents === this.category ){
        element.classList.add(this.activeClass)
      } else {
        element.classList.remove(this.activeClass)
      }
    }
  }

  tabswitching() {
    const targetArr = document.querySelectorAll('[data-tab-contents]');
    for (let i = 0; i < targetArr.length; i++) {
      const element = targetArr[i];
      if( element.dataset.tabContents === this.opts.content ){
        element.classList.add(this.activeClass)
      } else {
        element.classList.remove(this.activeClass)
      }
    }
    window.dispatchEvent(this.changeTabEvent)
  }

  changeTabQuery(query){
    if(this.category !== query){
      // console.log('changeTabQuery')
    }
  }

  scrollContentTop(){
    const contentElem = document.getElementById('serviceContentWrap');
    // const headerElem = document.getElementById('headerContent');
    // const contentElemTopPos = window.pageYOffset + contentElem.getBoundingClientRect().top - headerElem.clientHeight - 50
    const contentElemTopPos = window.pageYOffset + contentElem.getBoundingClientRect().top
    scrollTo(0, contentElemTopPos);
  }
}
