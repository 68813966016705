import State from './modules/CheckState'
import serviceSlider from './modules/serviceSlider.js'
/**
 * @returns {Class} New Constructor
 */
Array.prototype.forEach.call(document.querySelectorAll('[data-module]'), element => {
  const keys = element.getAttribute('data-module').split(/\s+/)
  const opts = element.getAttribute('data-options') || null

  keys.forEach(key => {
    import(`./modules/${key.charAt(0).toUpperCase() + key.slice(1)}`).then(
      module => {
        const options = opts ? keys.length > 1 ? JSON.parse(opts)[key] : JSON.parse(opts) : {}
        if (module !== void 0) return new module.default(element, options)
      }
    )
  })
})


function headingAnimationInit() {
  const targets = document.querySelectorAll('.js-heading-animation')
  Array.prototype.forEach.call(targets, function(element) {
    new SpanWrapText(element);
  })
}

function headingAnimation() {
  const targets = document.querySelectorAll('.js-heading-animation')
  Array.prototype.forEach.call(targets, function(element) {
    let flg = false
    if(!flg) {
      const rect = element.getBoundingClientRect().top;
      const offset = rect + State.scrollPosi;

      if (State.scrollPosi > offset - State.windowHeight + State.scrollOffset) {
        element.classList.add('is--action')
      }

      flg = true
    }
  })
}

class SpanWrapText {
  constructor(target) {
    this.target = target;
    this.nodes = this.target.childNodes;
    this.convert();
  }

  /**
   * @function convert
   * @description テキストを1文字ずつspanで囲む
   */
  convert() {
    let spanWrapText = '';

    this.nodes.forEach((node) => {
      if (node.nodeType == 3) { // テキストの場合
        // 改行コードを削除
        const text = node.textContent.replace(/\r?\n/g, '');
        // spanタグで囲んで連結
        spanWrapText = spanWrapText + text.split('').reduce((accumulator, currentValue) => {
          currentValue = currentValue.replace(' ', '&nbsp;');
          return accumulator + `<span>${currentValue}</span>`;
        }, '');
      } else { // テキスト以外の場合
        // brなどの要素はそのまま連結
        spanWrapText = spanWrapText + node.outerHTML;
      }
    });

    this.target.innerHTML = spanWrapText;
  }
}

// fire
headingAnimationInit()

window.addEventListener('load', function(){
  headingAnimation()
  checkSafari()

  if(this.location.pathname.includes('service')){
    serviceSlider()
  }
})

window.addEventListener('scroll', function(){
  headingAnimation()
});

function checkSafari(){
  const agent = window.navigator.userAgent.toLowerCase()
  if (agent.indexOf("safari") != -1 && agent.indexOf("chrome") < 0) {
    const checkSafariElmAry = document.getElementsByClassName('js-check-safari')
    for(let i=0; i<checkSafariElmAry.length; i++){
      checkSafariElmAry[i].classList.add('is-safari')
    }
  }
}
