import State from './CheckState'

export default class ScrollFadeIn {
  constructor(elem, opts) {
    this.target = elem
    this.opts = opts

    this.showClass = 'is--show'
    this.doneClass = 'is--done'

    this.eventBinds()
  }

  eventBinds() {
    window.addEventListener('load', ()=> {
      this.fadein()
    })

    window.addEventListener('scroll', ()=> {
      this.fadein()
    })
  }

  fadein() {
    const _this = this

    let flg = false
    if(!flg) {
      const rect = this.target.getBoundingClientRect().top;
      const offset = rect + State.scrollPosi;

      if (State.scrollPosi > offset - State.windowHeight + State.scrollOffset) {
        // 1. フェードイン発火クラスの付与
        this.target.classList.add(this.showClass)

        // 2. フェードインアニメーション完了判別クラスの付与
        //   NOTE: フェードイン用cssとhover用cssを分けるため。
        setTimeout(function() {
          _this.target.classList.add(_this.doneClass)
        }, 1000)


        if(_this.opts.type === 'button') {
          // 1. css animationの都合上、クラス削除
          setTimeout(function() {
            _this.target.classList.remove(_this.showClass)
          }, 1300)
        }
      }

      flg = true
    }
  }
}